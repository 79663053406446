<!-- <div class="col-lg-12">  sidebar-body-shrink-->
<div class="component-body">
  <div class="sidebar-body sidebar-body-shrink" id="sidebar">
    <app-sidefilter 
      [currentPage]="currentPage" 
      [filterOptions]="filterOptions" 
      (onSidebarToggle)="sidebarToggle()"
      (onOptionClick)="onOptionClick($event)">
    </app-sidefilter>
  </div>

  <div class="mainpage-body" id="mainbody" [ngSwitch]="currentPage">
    <!-- {{isAnalysisTabActive ? 'active' : ''}} -->
    <app-navbar class="homeNavBar {{isAnalysisTabActive ? 'active' : ''}}" [isSelect]="currentPage=='analysisNav'"
      [hidden]="!navBarShowpages.includes(currentPage)"></app-navbar>
    <div style="width: 100%;height: 100%;" [hidden]="currentPage!='home'">
      <span style="position: relative;">
        <!-- [hidden]="isAnalysisTabActive" -->
        <app-homepage [refresh]="isRefresh" (onShareFilterData)="getSideFilterData($event)"></app-homepage>
      </span>
    </div>
    <div [hidden]="currentPage=='home'" style="width: 100%;height: 100%;">
      <span *ngSwitchCase="'admin'"><app-admin-panel></app-admin-panel></span>
      <span *ngSwitchCase="'companyHunter'"><app-company-hunter></app-company-hunter></span>
      <span *ngSwitchCase="'profile'"><app-user-profile [option]="profileOption"></app-user-profile></span>
      <span *ngSwitchCase="'workspace'"><app-workstation [type]="currentPage"></app-workstation></span>
      <span *ngSwitchCase="'download'"><app-workstation [type]="currentPage"></app-workstation></span>
      <span *ngSwitchCase="'notification'"><app-notification></app-notification></span>
      <span *ngSwitchCase="'favourites'"><app-workstation [type]="currentPage"></app-workstation></span>
      <span *ngSwitchCase="'analysis'"><app-analysis></app-analysis></span>
      <span *ngSwitchCase="'load'">
        <div class="loader"><img src="assets/images/loader.png" alt=""><span>Please wait...</span></div>
      </span>
      <span *ngSwitchCase="'analysisNav'">
        <app-whats-trending [currentPage]="currentAnalysisPage"></app-whats-trending>
      </span>
      <span *ngSwitchDefault>
        <div class="h-100 d-flex justify-content-center align-items-center">
          <h1 style="font-family:'Roboto';">404 not found</h1>
        </div>
      </span>
    </div>
  </div>
  
  <div class="full-page" *ngIf="isSearchingData">
    <div class="quote-container">
      <div class="knowledge-box">
        <img src="assets/images/idea.png" alt="">
        <h4>{{loadingFacts[currentFact]}}</h4>
      </div>
      <span class="bar-text"><img src="assets/images/loader4.gif" alt="" /></span>
      <button class="cypher-btn green-button cancel-btn" (click)="onCancelClick()">Cancel</button>
    </div>
  </div>

  <div *ngIf="false" class="show-alert position-absolute d-flex justify-content-center align-items-center {{isCloseAlertBox?'closePop':''}}">
    <div class="content-box position-relative">
      <img (click)="closeAlertBos()" src="assets/images/cross.png" alt="" class="close position-absolute"/>
      <div class="img"><img src="assets/images/chandrayan.jpeg" alt=""/></div>
      <div class="body">
        <h4>🌌 ISRO's Chandrayaan-3: Mission Accomplished! 🌕</h4>
        <p>Hats off to ISRO for conquering the cosmos once again with the Chandrayaan-3 mission success! A giant leap for India's space exploration. 🚀👏</p>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->