export const environment = {
  production: true,
  india: "data",
  unlimitedDownload: 2000000,
  unlimitedSearch: 50000,
  apiurl:'https://www.api.cypherexim.com/',
  // apiurl:'http://localhost:8080/',
  linkedInBaseUrl: "https://api.linkedin.com/",
  apiDataCache: {}
};

// apiurl: 'http://ec2-18-232-9-18.compute-1.amazonaws.com:8080/',

