<div class="contentmain {{!isToggleClosed && 'toggle-on'}}">
    <span tabindex="1" class='header-btns' id="btn-0" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'trending')">What's Trending</span>
    <span tabindex="1" class="header-btns" id="profile-btn-1" data-click="self" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'companyProfile')">Company Profile</span>
    <span tabindex="1" class='header-btns' id="btn-2" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'hsCodeAnalysis')">Hs Code Analysis</span>
    <span tabindex="1" class='header-btns' id="btn-3" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'countryAnalysis')">Country Analysis</span>
    <span tabindex="1" class='header-btns' id="btn-4" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'timeAnalysis')">Time Analysis</span>
    <span tabindex="1" class='header-btns' id="btn-4" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'exporterAnalysis')">Supplier Analysis</span>
    <span tabindex="1" class='header-btns' id="btn-4" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'importerAnalysis')">Buyer Analysis</span>
    <span tabindex="1" class='header-btns' id="btn-5" (focusout)="onFocusOut($event)" (click)="onClickNavBtn($event, 'customizeAnalysis')">Customize Analysis</span>
</div>


