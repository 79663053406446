<div class="helpdesk-container">
    <div class="download-container">
        
    </div>

    <div class="helpdesk">

    </div>
</div>

