<div class="container-box">
    <div class="notification-container" *ngIf="pageType=='notification'">
        <div class="tab-btn active-tab" #btn1 (click)="onClickTab(btn1, btn2)">Alert</div>
        <div class="tab-btn" #btn2 (click)="onClickTab(btn2, btn1)">Notification</div>

        <div class="alert-box" *ngIf="notificationPageType=='alert'">
            <div class="form-container">
                <div class="data-field">
                    <span>Start Date:</span>
                    <input type="date" [(ngModel)]="dates.start" onfocus="this.showPicker()" />
                </div>
                <div class="data-field">
                    <span>End Date:</span>
                    <input type="date" [(ngModel)]="dates.end" onfocus="this.showPicker()"/>
                </div>

                <div class="data-field editor-inp">
                    <span>Message:</span>
                    <div class="editor" #alertEditor contenteditable="true" (input)="onTypeEditor($event, 'alert')"></div>
                </div>

                <div class="data-field checkbox-inp">
                    <span>Show popup:</span>
                    <input type="checkbox" [(ngModel)]="showPopup" />
                </div>
                <div class="submit text-center"><button class="btn btn-success" (click)="onSubmitAlertMsg(alertEditor)">Submit</button></div>
            </div>
        </div>

        <div class="notification-box" *ngIf="notificationPageType=='push'">
            <div class="form-container">
                <div class="category-field">
                    <span>Select Category:</span>
                    <select>
                        <option value="normal">Normal</option>
                        <option value="announce">Annoucement</option>
                        <option value="warning">Alert/Warning</option>
                    </select>
                </div>
                <div class="data-field editor-field">
                    <span>Message:</span>
                    <div class="editor" [textContent]="message.push" (input)="onTypeEditor($event, 'push')" contenteditable="true"></div>
                </div>
                <div class="submit text-center"><button [disabled]="message.push.length==0" (click)="onSubmitNotification()" class="btn btn-success">Submit</button></div>
            </div>
        </div>
    </div>

    <div class="reset-password-container" *ngIf="pageType=='resetPassword'">
        <h3>Reset Password</h3>
        <div class="data-field">
            <span>Select Email:</span>
            <div class="dropdown-box">
                <div class="input-txt">
                    <div class="w-100 h-100 items-list" *ngIf="selectedItemArr.length>0"><span *ngFor="let item of selectedItemArr">{{item}} <a (click)="selectedItemArr=[]">&#x2715;</a></span></div>
                    <input type="text" *ngIf="selectedItemArr.length==0" (click)="isEmailDropdownOn=true" (keyup)="contactFilter()" [(ngModel)]="searchInp" />
                </div>
                <div class="option-list" *ngIf="isEmailDropdownOn">
                    <div class="option" (click)="onSelectEmail(item)" *ngFor="let item of copyAllUsers">
                        <span><img src="assets/images/account.png" alt="" /></span> {{item}}
                    </div>
                </div>
            </div>
        </div>
        <div class="submit text-center"><button (click)="onSubmitResetPass()" [disabled]="selectedItemArr.length==0" class="btn btn-success">{{isSubmitClicked ? 'Updating...' : 'Update'}}</button></div>
    </div>
</div>

