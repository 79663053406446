<div class="company-hunter-container d-flex align-items-start justify-content-center">
    <div class="score-board" *ngIf="currentSection=='search'">
        <i class="fa-solid fa-list me-4" #listIcon id="listIcon" (click)="openFavoriteCompanies(listIcon)" title="Favourite List"></i>
        <div class="points {{companyPoints>=20 && companyPoints<50 ? 'orange' : companyPoints<10 ? 'red':'green'}}">
            <span>Remaining:</span>&nbsp;&nbsp;<span>{{companyPoints}}</span>
        </div>
    </div>

    <div class="middle-box w-50" *ngIf="currentSection=='search'">
        <div class="company-search-box w-100">
            <h3 class="text-center">SEARCH YOUR REQUIRED COMPANY</h3>
    
            <div class="input-fields d-flex align-items-center">
                <div class="search-field h-100 w-100 position-relative">
                    <input 
                        [(ngModel)]="typedTxt"
                        (keyup)="onKeyUpInput('searchList')"                          
                        [value]="typedTxt" 
                        type="search" 
                        placeholder="Search company here" 
                        class="h-100 w-100" id="" 
                    />
                    <ul id="searchList" tabindex="1" (focusout)="onFocusOutSearch()" class="searched-list position-absolute" *ngIf="companyList.length>0">
                        <li (click)="onClickListItem(item?.company_name)" *ngFor="let item of companyList">{{item?.company_name}}</li>
                    </ul>
                </div>
            </div>
    
            <div class="bttn text-center">
                <button class="btn btn-danger" (click)="onSearchCompany()">Search</button>
            </div>
        </div>

        <div *ngIf="hasDataReceived" class="company-search-details">
            <i class="fa-{{isFavoriteCompany ? 'solid' : 'regular'}} fa-heart {{!isPadLockUnlocked && 'd-none'}}" ngbTooltip="Add to favorites" (click)="saveIntoFavorites()"></i>
            <i *ngIf="companyData.length>0" class="lock-icon fa-solid fa-{{!isPadLockClicked ? 'lock': 'spinner fa-spin-pulse'}} {{isPadLockUnlocked && 'd-none'}}" (click)="unlockPad()"></i>
            <div class="load-box w-100 d-flex align-items-center justify-content-center" *ngIf="companyData.length==0">
                <div class="loader">
                    <img *ngIf="isLoading" src="assets/images/loader.png" alt="" />
                    <span *ngIf="isLoading">Please wait...</span>
                    <h4 *ngIf="!isLoading && !hasAppliedForNewCompany">No Data Available</h4>

                    <div class="bottom-btn" *ngIf="!isLoading">
                        <button *ngIf="!hasAppliedForNewCompany" class="btn btn-success" (click)="requestForNewCompany()">
                            <i *ngIf="isApplyInProgress" class="fa-solid fa-spinner fa-spin-pulse"></i>
                            <span *ngIf="!isApplyInProgress">Request</span>
                        </button>
                        <span class="req-msg" *ngIf="hasAppliedForNewCompany">Company details request is sent. Data would be available within or upto 24Hrs.</span>
                    </div>
                </div>
            </div>
            
            <div class="w-100 h-100 {{!isPadLockUnlocked ? 'blurred': ''}}" *ngIf="companyData.length>0">                
                <h2 class="text-center">{{companyData?.companyName}}</h2>
    
                <div class="basic-data w-75 mx-auto">
                    <div class="data d-flex align-items-center mb-3 {{companyData[item?.key]=='NA' && 'd-none'}}" *ngFor="let item of companyInfoPoints">
                        <i class="fa-solid fa-{{item?.icon}} me-3"></i>
                        <p class="mb-0">{{companyData[item?.key]}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="favorites-list-container" *ngIf="currentSection=='list'">
        <div class="table-heading text-center w-100">
            <i class="fa-solid fa-arrow-left" title="Go Back" (click)="currentSection='search'"></i>
            <span class="{{favoriteCompanyList.length==0 && 'invisible'}}">Favourite Companies</span>
            <i *ngIf="favoriteCompanyList.length>0" (click)="onDownloadExcelFile()" class="fa-solid fa-download me-4" title="Download Excel"></i>
        </div>

        <div *ngIf="favoriteCompanyList.length>0" class="table-box">
            <table class="w-100">
                <thead>
                    <th>S. no.</th>
                    <th>Company</th>
                    <th>IEC</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Contact Person</th>
                    <th>Location</th>
                    <th>Address</th>
                </thead>
    
                <tbody>
                    <tr *ngFor="let item of favoriteCompanyList;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item?.company_name || 'N/A'}}</td>
                        <td>{{item?.iec || 'N/A'}}</td>
                        <td>{{item?.contact || 'N/A'}}</td>
                        <td>{{item?.email || 'N/A'}}</td>
                        <td>{{item?.person_name || 'N/A'}}</td>
                        <td>{{item?.location || 'N/A'}}</td>
                        <td>{{item?.address || 'N/A'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="favoriteCompanyList.length==0" class="empty-msg d-flex align-items-center justify-content-center">
            <i *ngIf="isListInFetching" class="fa-solid fa-circle-notch fa-spin" style="color:#4cbfa6;font-size:1.8rem;"></i>
            <span *ngIf="!isListInFetching">No Favorites</span>
        </div>
    </div>
</div>